<template>
    <loading-layer :is-loading="buffer.isLoading" />

    <form
        :loading="buffer.isLoading"
        @submit="checkForm"
    >
        <div class="form-header">
            <p class="h6 font-weight-bold">
                {{ __('MENUBAR.ECONOMICS') }}
                <help wiki-id="economics" />
            </p>
            <input
                v-if="buffer.loadingErrorMessage == ''"
                class="btn-wide btn-primary ml-auto"
                type="submit"
                :value="__('BUTTONS.UPDATE')"
            >
        </div>
        <p
            v-if="buffer.formError.hasErrors"
            class="text-danger text-right"
        >
            There are errors in the form !!
        </p>

        <div
            v-if="buffer.errorMessage != ''"
            class="alert alert-danger"
            role="alert"
        >
            {{ buffer.errorMessage }}
        </div>

        <div
            v-if="buffer.successMessage != ''"
            class="alert alert-success"
            role="alert"
        >
            {{ buffer.successMessage }}
        </div>

        <year-selector
            v-model="buffer.selectedYear"
            @year-updated="updateYear()"
        />

        <div
            v-if="buffer.loadingErrorMessage != ''"
            class="alert alert-danger"
            role="alert"
        >
            {{ buffer.loadingErrorMessage }}
        </div>

        <div v-if="buffer.loadingErrorMessage == '' && !buffer.isLoading">
            <div
                v-for="facility in buffer.facilities"
                :key="facility.name"
            >
                <p class="h4">
                    {{ facility.name }}
                </p>

                <div
                    v-for="activityId in Object.keys(facility.activities)"
                    :key="activityId"
                    class="ml-3"
                >
                    <p class="h5">
                        > {{ getActivityName(activityId) }}
                    </p>

                    <!-- <div class="ml-3 d-flex flex-wrap">
                        <kpi
                            v-for="kpi in buffer.activityKpis[activityId].kpis"
                            :key="kpi.kpiId"
                            v-model="buffer.valueDivider[facility.name][activityId][kpi.kpiId].value"
                            class="p-1"
                            :name="kpi.name"
                            :unit="kpi.unit"
                            :error="buffer.valueDivider[facility.name][activityId][kpi.kpiId].error"
                        />
                        <button @click="divide">Divide</button>
                    </div> -->

                    <div
                        v-for="specieId in facility.activities[activityId].specieIds"
                        :key="specieId"
                        class="ml-3"
                    >
                        <p class="h6">
                            · {{ getSpecieName(specieId) }}
                        </p>
                        <!-- KPIS -->

                        <div class="ml-3 d-flex flex-wrap">
                            <kpi
                                v-for="kpi in buffer.activityKpis[activityId].kpis"
                                :key="kpi.kpiId"
                                v-model="formData[facility.name][activityId][specieId][kpi.kpiId].value"
                                class="p-1"
                                :name="kpi.name"
                                :unit="kpi.unit"
                                :error="formData[facility.name][activityId][specieId][kpi.kpiId].error"
                                :help-id="buffer.kpisWithHelp.includes(kpi.kpiId) ? `kpi-${kpi.kpiId.toString()}` : ''"
                            />
                        </div>
                    </div>
                    <br>
                </div>
                <br>
            </div>
        </div>
    </form>
</template>

<script>
import YearSelector from '../../components/year_selector.vue';
import LoadingLayer from '../../components/loading_layer.vue';
import Kpi from '../../components/kpi.vue';
import Help from '../../components/help.vue';

import Constants from '../../Constants';

import TooltipStrings from '../../lang/tooltips.json';

import { getActivityName, getSpecieName, getKpisFromType } from '../../db/db-utils.js';
import { isInt } from '../../utils/form-utils.js';

import API from '../../utils/api-utils.js';
import __ from '../../utils/translator.js';

const economicsKPIs = getKpisFromType(Constants.KPI_ECONOMICS_ID);

export default {

    components: {
        Help,
        Kpi,
        YearSelector,
        LoadingLayer
    },
    inject: ['GLOBAL_ACTIVITIES'],

    data() {
        return {
            buffer: {
                //TEMP:
                valueDivider: {},


                activityKpis: {}, //kpi classified in activities
                kpisConfig: {}, //kpi index contain config of thay kpi: {<kpiId>: {config} }
                facilities: [],

                kpisWithHelp: [14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
                yearRange: Array.from(
                    Array(
                        Constants.MAX_KPIDATE_VALUE - (Constants.MIN_KPIDATE_VALUE-1)).keys()
                    ).map(e => e + Constants.MIN_KPIDATE_VALUE),

                selectedYear: Constants.MAX_KPIDATE_VALUE,

                isLoading: true,
                errorMessage: '',
                successMessage: '',
                loadingErrorMessage: '',

                formError: {
                    hasErrors: false
                },

                tooltipString: TooltipStrings
            },

            /**
             * Hierarchy:
             * Form data -> facility -> activity -> specie -> kpi -> { value, error }
             */
            formData: {}
        }
    },

    //First of all, fetch API for facilities, and if exists fetch data.
    created() {
        const promises = [];
        promises.push(this.fetchFacilities());
        promises.push(this.classifyKpisInActivities());
        promises.push(this.setKPIsConfiguration());
        Promise.all(promises)
            .then(() => this.cleanupFacilities())
            .finally(() => this.updateYear());
    },

    methods: {
        // divide(ev) {
        //     ev.preventDefault();
        //     ev.stopPropagation();

        //     Object.entries(this.buffer.valueDivider).forEach(([facility, activities]) => {
        //         Object.entries(activities).forEach(([activityId, kpis]) => {
        //             let divider = Object.keys(this.formData[facility][activityId]).length;
        //             Object.entries(kpis).forEach(([kpiId, kpiObj]) => {
        //                 let value = kpiObj.value / divider;
        //                 Object.entries(this.formData[facility][activityId]).forEach(([specieId, kpi]) => {
        //                     if (value)
        //                         this.formData[facility][activityId][specieId][kpiId].value = value;
        //                     console.log(specieId, kpiId, kpi, value);
        //                 });
        //             });
        //         });
        //     });
        // },


        updateYear() {
            this.resetMessages();

            this.buffer.isLoading = true;
            const year = this.buffer.selectedYear;

            API.read.kpi.economics(year)
                .then(response => {
                    if (response.ok) {
                        this.setDataIntoForm(response.data);
                    } else {
                        this.buffer.loadingErrorMessage = response.error.message;
                    }
                })
                .catch(() => this.buffer.loadingErrorMessage = 'Network error, try again later.')
                .finally(() => this.buffer.isLoading = false);
        },

        setDataIntoForm(apiData) {
            this.resetFormStructure();

            apiData.forEach(kpi => {
                /**
                 * Check existance of indexes in the form,
                 * facility / activity / specie may be removed
                 */
                if (kpi.facility in this.formData &&
                    kpi.activityId in this.formData[kpi.facility] &&
                    kpi.specieId in this.formData[kpi.facility][kpi.activityId] &&
                    kpi.kpiId in this.formData[kpi.facility][kpi.activityId][kpi.specieId])
                        this.formData[kpi.facility][kpi.activityId][kpi.specieId][kpi.kpiId].value = kpi.value
            });
        },

        resetMessages() {
            this.buffer.errorMessage = '';
            this.buffer.successMessage = '';
            this.buffer.loadingErrorMessage = '';
            this.buffer.formError.hasErrors = false;
        },

        checkForm(ev) {
            ev.preventDefault();

            this.resetMessages();

            let ok = true;
            
            //KpiIds to get count of percentage
            const percentageKpis = ['14', '15', '16', '17', '22'];

            const data = [];
            Object.entries(this.formData).forEach(([facility, activities]) => {
                Object.entries(activities).forEach(([activityId, species]) => {
                    Object.entries(species).forEach(([specieId, kpis]) => {

                        let percentageKpiSum = 0; //Must not be more than 100
                        Object.entries(kpis).forEach(([kpiId, kpiObj]) => {
                            // first of all, reset errors
                            kpiObj.error = '';

                            const value = kpiObj.value;
                            const currentConfig = this.buffer.kpisConfig[kpiId];

                            //Validate form
                            if (value === '') {
                                kpiObj.error = 'Empty';
                                ok = false;
                                return;
                            }

                            if (value > currentConfig.max || value < currentConfig.min) {
                                kpiObj.error = `Value must be between ${currentConfig.min} and ${currentConfig.max}`;
                                ok = false;
                                return;
                            }

                            //type check
                            if (currentConfig.type == 'int' && !isInt(value)) {
                                kpiObj.error = `Value must be integer`;
                                ok = false;
                                return;   
                            }

                            if (currentConfig.type == 'float' && Number(value) !== value) {
                                kpiObj.error = `Value must be numeric`;
                                ok = false;
                                return;   
                            }

                            //Percentaje of kpis must not be greater than 100: 
                            //KpiIds: 14, 15, 16, 17, 22
                            if (percentageKpis.indexOf(kpiId) != -1) {
                                percentageKpiSum += value;

                                if (percentageKpiSum > 100) {
                                    //Add an error message to every kpi of selected specie
                                    percentageKpis.forEach(kpiId => {
                                        const k = kpis[kpiId];
                                        if (k != undefined) {
                                            k.error = 'Sum of this kpis must be less than 100';
                                        }
                                    });

                                    ok = false
                                    return;
                                }
                            }

                            //Store form data ready to send
                            data.push({
                                facility,
                                kpiId: Number(kpiId),
                                value,
                                specieId: Number(specieId),
                                activityId: Number(activityId),
                                year: this.buffer.selectedYear,
                                typeId: Constants.KPI_ECONOMICS_ID
                            });
                        });
                    });
                });
            });

            if (!ok) {
                this.buffer.formError.hasErrors = true;
                return;
            }

            this.buffer.isLoading = true;
            API.update.kpi(data)
                .then(response => {
                    if (response.ok) {
                        this.buffer.successMessage = 'Kpis successfully updated.';
                    } else {
                        this.buffer.errorMessage = response.error.message;
                    }
                })
                .catch(() => this.buffer.loadingErrorMessage = 'Network error, try again later.')
                .finally(() => this.buffer.isLoading = false);
        },

        cleanupFacilities() {
            /**
             * Cleanup facilities
             * Tranforms: [
             *     {
             *         activityId: 0,
             *         specieId: 0
             *     },
             *     {
             *         activityId: 0,
             *         specieId: 1
             *     }
             * ]
             * 
             * into: 
             * {
             *      <activityId>: { specieIds: [<specieId>, <specieId>] }
             * }
             * 
             */
            this.buffer.facilities.forEach(fac => {
                const activitiesReduced = {};

                fac.activities.forEach(act => {
                    const prop = act.activityId;
                    if (prop != 2) { //SKIP PROCESSING ACTIVITY
                        if (activitiesReduced[prop] == undefined) 
                            activitiesReduced[prop] = { specieIds: [] };

                        activitiesReduced[prop].specieIds.push(act.specieId);
                    }
                });
                fac.activities = activitiesReduced;
            });
        },

        /**
         * Builds data structure for storaging form data
         * 
         * An object hirarchy is created for storaging indexed information for each kpi.
         * Data is accessed in this way:
         * formData[<facilityName>][<activityId>][<specieId>][<kpiId>].value
         */
        resetFormStructure() {
            this.buffer.facilities.forEach(fac => {
                this.formData[fac.name] = {}
                this.buffer.valueDivider[fac.name] = {}

                Object.entries(fac.activities).forEach(([activityId, specie]) => {
                    this.formData[fac.name][activityId] = {};
                    this.buffer.valueDivider[fac.name][activityId] = {}

                    specie.specieIds.forEach(specieId => {
                        this.formData[fac.name][activityId][specieId] = {};

                        this.buffer.activityKpis[activityId].kpis.forEach(kpi => {
                            this.formData[fac.name][activityId][specieId][kpi.kpiId] = {
                                value: '',
                                error: ''
                            };
                            this.buffer.valueDivider[fac.name][activityId][kpi.kpiId] = {
                                value: '',
                                error: ''
                            }
                        });
                    });
                });
            });
        },

        async fetchFacilities() {
            await API.read.facility()
                .then(response => {
                    if (response.ok) {
                        this.buffer.facilities = response.data;
                    } else {
                        this.buffer.loadingErrorMessage = response.error.message;
                    }
                })
                .catch(() => this.buffer.loadingErrorMessage = 'Network error, try again later.');
        },

        /**
         * Classifys kpis of economics type into activities
         * 
         * {
         *     <activityId>: { kpis: [arrayOfKpis] }
         * }
         */
        async classifyKpisInActivities() {
            this.GLOBAL_ACTIVITIES.forEach(act => {
                //Some kpis are common
                const kpis = economicsKPIs.filter(kpi => kpi.activityId == act.id || kpi.activityId === null);
                this.buffer.activityKpis[act.id] = {
                    kpis
                };
            });
        },

        async setKPIsConfiguration() {
            economicsKPIs.forEach(kpi => {
                this.buffer.kpisConfig[kpi.kpiId] = kpi.config;
            });
        },

        getActivityName,
        getSpecieName,
        __ //TRANSLATOR
    }
}
</script>